import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import MainHeader from '../components/shared/MainHeader';
import Footer from "../components/shared/Footer";
import { Link } from 'react-router-dom'; 
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";
import  get_url  from '../events/common/ApiCaller';
import Moment from 'react-moment';
import moment from 'moment';
import queryString from 'query-string'
import $ from 'jquery';
import {useNavigate} from 'react-router-dom';
import style from '../App/public/css/super.css';
import { ThreeDots } from 'react-loader-spinner'

const PricingPlans = (props) => {
    const navigate = useNavigate();
    
    const [userDetail, setUserDetail] = useState([]);
    const [subscriptionDetail, setSubscriptionDetail] = useState([]);
    const[isLoaderDisplay,setIsLoaderDisplay] = useState(false);

    let header = { headers: {
        "Authorization" : `Bearer ${localStorage.getItem('login_token')}`,
        "event_id" : localStorage.getItem('event_id'),
        "user_role" : localStorage.getItem('role')
    }}

    let stripePromise;
    const getStripe = () => {
        if (!stripePromise) {
            stripePromise = loadStripe('pk_live_51LeJjzDd7PHj8FTXegXqLQcMyoje6tEzk0qYLfPpfIMHTE4MMpHl5rKAjjhiQ7iOOkBzwD9wSPcYMhVb6b1tnYFN00dNQyOHiQ');
        }
        return stripePromise;
    };

    async function handleCheckout(price_id) {
        if (!localStorage.getItem('login_token')) {
            alert('Please login to subscribe to free plan.');
            navigate('/Login')
        }
        await axios.post(`${get_url.local_url}create-checkout-session`, {price_id: price_id}, header)
          .then((response) => {
            if (400 === response.data.status) {
                alert(response.data.message); return false;
            }

            if (409 === response.data.status) {
                navigate('/Login');
            }
            window.location.href = response.data.data.url;
          })
          .catch((error) => {
            
        });
    }

    const getUserDetail = async() => {
        await axios.get(`${get_url.local_url}get-user`,header).then((response) => {
            const get_user_detail  = response.data.data[0] 
            if (get_user_detail) {
                setUserDetail(get_user_detail) 
            }
        })
    }
    const getSubscriptionDetail = async() => {
        await axios.get(`${get_url.local_url}get_subscription`).then((response) => {
            const get_subscription_detail  = response.data.data 
            setSubscriptionDetail(get_subscription_detail) 
        })
    }


    const subscriptionElement = subscriptionDetail.length > 0 ? subscriptionDetail.map((sub_data, sub_key) => {
      return (
        <>
          <div className="Pricing_card col-lg-4 col-md-6 col-sm-12 shadow-lg">
            <div className="Pricing_C_top">
              <p className="Tier">Tier {sub_key + 1}</p>
              <hr className="Divider" />
              <p className="Plan_Name">{sub_data.plan_name}</p>
              <p className="price">
                {" "}
                $ {sub_data.plan_amount}
                <span className="post_bottom">/month</span>
              </p>

              <Button
                variant="outline-primary Buy_btn"
                onClick={() => {
                  handleCheckout(sub_data.stripe_amount_id);
                }}
              >
                Get Started
              </Button>
            </div>
            <hr></hr>
            <div className="pricing_features">
              <ul className="Pricng_point">
                <li className="picing_item">
                  <i className="fas fa-check"></i>
                  <p className="F_point">
                    {sub_data.create_events == -1
                      ? "Unlimited"
                      : sub_data.create_events}{" "}
                    Events at the Same Time{" "}
                  </p>
                </li>

                <li className="picing_item">
                  <i className="fas fa-check"></i>
                  <p className="F_point">
                    {sub_data.team_member == -1
                      ? "Unlimited"
                      : sub_data.team_member}{" "}
                    Team Members{" "}
                  </p>
                </li>

                <li
                  className={`picing_item ${
                    sub_data.role_based_access == 0 ? "d-none" : ""
                  }`}
                >
                  <i className="fas fa-check"></i>
                  <p className="F_point"> Role Based Access for Team Members</p>
                </li>

                <li
                  className={`picing_item ${
                    sub_data.automated_checklist == 0 ? "d-none" : ""
                  }`}
                >
                  <i className="fas fa-check"></i>
                  <p className="F_point">Automated Checklist Creation </p>
                </li>

                <li
                  className={`picing_item ${
                    sub_data.budget == 0 ? "d-none" : ""
                  }`}
                >
                  <i className="fas fa-check"></i>
                  <p className="F_point">Budgeting Tools </p>
                </li>

                <li
                  className={`picing_item ${
                    sub_data.checklist_reminder == 0 ? "d-none" : ""
                  }`}
                >
                  <i className="fas fa-check"></i>
                  <p className="F_point">Time-Relevant Checklist Reminders </p>
                </li>

                <li
                  className={`picing_item ${
                    sub_data.is_crm == 0 ? "d-none" : ""
                  }`}
                >
                  <i className="fas fa-check"></i>
                  <p className="F_point">Contacts/CRM </p>
                </li>

                <li
                  className={`picing_item ${
                    sub_data.chat_support == 0 ? "d-none" : ""
                  }`}
                >
                  <i className="fas fa-check"></i>
                  <p className="F_point">Email Support + Call Support </p>
                </li>
                <li
                  className={`picing_item ${
                    sub_data.training == 0 ? "d-none" : ""
                  }`}
                >
                  <i className="fas fa-check"></i>
                  <p className="F_point">3 Free Live Trainings</p>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    }): null;



    useEffect(() => { 
        if (null !== localStorage.getItem('login_token')) {
            getUserDetail()   
        }
        getSubscriptionDetail()   
       
    },[]);

    const setCurrentUser = [{}];
    // console.log('subscriptionDetail',subscriptionDetail)
    return (
        <>
        {isLoaderDisplay? <div className='overlay'><div className="center_dots"><ThreeDots height="80" width="80" radius="9" color="#0d6efd" /></div></div>:''}

        <MainHeader/> 
            <section  className='Pricing_Banner'>
                <div className='Pricing_B_text'>
                    <div> </div>
                    <p className='head_Text'>Pricing Plans</p>
                    <p className='Tagline'>Event Management is easy now!</p>
                </div>
            </section>
            <section className=''>
                <div className='pricing_card_outer row' > 
                   

                    

                    { subscriptionElement } 
                </div>
                


            </section>

        <Footer/> 
        </>
    );
}
export default PricingPlans;