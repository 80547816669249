import React from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  get_url  from '../events/common/ApiCaller';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";   
//import MainHeader from "../../components/shared/MainHeader";
//import Footer from "../../components/shared/Footer"
import axios from "axios";
import AppleLogin from 'react-apple-login'

const Login = (props) => { 
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    email: yup.string().email().required("email name is required"),
    password: yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
  
  });
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => { 
    const headers = { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json" }
    const data1 = {  email:data.email, password:data.password, role:'admin'};

    axios.post(`${get_url.local_url}login`,data1,{headers:headers}).then((response) => {
      if(response.data.token & response.data.token !== 'undefined') {
        window.localStorage.clear();
        navigate('/')
      }
      if(response.data.status == '200'){
          //localStorage.setItem('userData', JSON.stringify(response.data.data));
          localStorage.setItem('login_token', response.data.token);
          localStorage.setItem('role', response.data.data.role);
          //localStorage.setItem('user', JSON.stringify(response.data.data.id));
        
        // localStorage.setItem('user_id', JSON.stringify(response.data.data._id));
          if(response.data.data.role=="admin"){
            toast.success("Admin Login Successfully !");
            navigate('/Welcome');
          }else if(response.data.data.role=="user"){ 
            //localStorage.setItem('userData', JSON.stringify(response.data.data));
            //localStorage.setItem('user_id', JSON.stringify(response.data.data.user_id));
            localStorage.setItem('event_id', response.data.data.event_id);
            toast.success("User Login Successfully !");
            navigate('/CheckList/'+response.data.data.event_id);
          }else{
            toast.error("response.data.message");
            navigate('/Login');
          } 
        }else{
          alert(response.data.message)
          toast.success(response.data.message);
        }
    });
  }

  const appleResponse = response => {
    if (!response.error) {
      axios
        .post(get_url.local_url+"auth", response)
        .then((res) => {
          console.log("Login appleResponse", res.data)
          localStorage.setItem('login_token',res.data.token)
              localStorage.setItem('role', res.data.data.role);
              localStorage.setItem('email', res.data.data.eamil);
              navigate('/Welcome');
          
          } 
          // this.setState({ authResponse: res.data })
        )
        .catch(err => console.log(err));
    }
  };
  
  return (
    <>
    {/* <MainHeader/> */}
      <div>
        <div className='signup_container shadow'>
            <div className='image_con'>
                 <div className='motive_text overlay1'>
                    <h2>Manage your events perfectly</h2>
                 </div>
            </div>
            <div className='form_con'>
                  <div className='action_form'>
                      <h2>Login</h2>
                      <Form onSubmit={handleSubmit(onSubmit)}> 
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Control className='E_mail' type="email" placeholder="Enter email" name="email" {...register('email', { required: true })} autoComplete="off" />
                            {errors.email &&<span className='custom-error'>Email is required</span>} 
                          </Form.Group>
                        </Row>
                        
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Control className='P_assword' type="password" placeholder="Password" name="password" {...register('password', { required: true })} autoComplete="off"/> 
                            {errors.password &&<span className='custom-error'>{errors.password?.message}</span>} 
                          </Form.Group>
                        </Row>
                
                        <Row className="mb-3">
                            <Link className='Forgot_P' to="/ForgotPassword" > Forgot Password?</Link>
                        </Row>
                        
                        

                        <Button className="btn button_mg" type="submit" >
                          Login
                        </Button>
                        
                        <div className="login_with_div">
                           
                           <div className='more_login_text'> Or Login with</div>
                        </div>

                        <div className='Login_with_link'>
                      

                        <AppleLogin 
                          clientId="app.easyevents.webapp"
                          scope="name email"
                          redirectURI="https://easyevents.app/SignUp" 
                          //callback={appleResponse}
                          responseMode='query'
                          />
                        </div>
                       

                        {/* <ul className="social_login_ul">
                          <li><div style={{ marginBottom: "1rem" }}>
        <GoogleLogin />
      </div></li> */}
                          {/* <li><Link href="#"><i class="fa-brands fa-google"></i></Link></li> */}
                          {/* <li><a href="#"><img src={L2} /></a></li>
                          <li><a href="#"><img src={L3} /></a></li>
                          <li><a href="#"><img src={L4} /></a></li> */}
                        {/* </ul> */}
                        <div className="not_account">Don’t have an account? <Link to="/Signup">Create new Account</Link></div>
                      </Form>
                  </div>
            </div>
          
        </div>
      </div>
      {/* <Footer/>  */}
    </>
  )
}
export default Login;